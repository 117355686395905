<template>
  <div :class="['project-types', { loader: loader }]">
    <ui-loader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="!loader"
        :title="$t('project-types')"
        :actionText="$t('add-type')"
        showAction
        trash-is-hidden
        :data="getEsgTypes.data"
        :descriptionList="descriptionList"
        hideEditIcon
        @changeRowField="changeRowField($event)"
        @addRowField="addRowField($event)"
        @deleteRowField="deleteRowField($event)"
        :sort="true"
        :isGlobeIcon="true"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiLoader from "@/components/ui/UiLoader";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "IncidentTypes",
  components: {
    UiNotification,
    UiLoader,
    Table: () => import("@/components/ui/UiTable"),
  },

  data() {
    return {
      descriptionList: [
        {title: this.$t('title'), width: 440}
      ],
      loader: true,
      showNotification: false,
      message: ''
    }
  },
  methods: {
    ...mapActions([
      'esgTypes',
      'changeEsgType',
      'addEsgType',
      'deleteEsgType'
    ]),
    changeRowField(data) {
      this.loader = true;

      this.changeEsgType({
        name: data.name,
        id: data.id,
        ord: +data.ord,
        nameInt: {
          en: data.nameIntEn
        }
      }).then(() => {
        this.esgTypes()
        setTimeout(() => {
          this.loader = false
          this.message = this.$t('type-successfully-updated')
          this.showNotification = true
        }, 500)
      })
    },

    addRowField(data) {
      this.loader = true;

      this.addEsgType({
        name: data.name,
        ord: +data.ord
      }).then(() => {
        this.esgTypes()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('type-successfully-added')
        }, 500)
      })
    },

    deleteRowField (data) {
      this.loader = true;

      this.deleteEsgType({
        id: data.id
      }).then(() => {
        this.esgTypes()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('type-successfully-deleted')
        }, 500)
      })
    }
  },

  computed: {
    ...mapGetters(['getEsgTypes'])
  },

  mounted() {
    this.esgTypes().then(() => {
      this.loader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.project-types {
  width: 100%;
  max-width: 1000px;

  &.loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>